<template>
    <div class="flex items-center justify-center gap-2 mt-4 pb-12 text-lg">
        <NuxtLink v-if="currentPage > 1" :to="pageLink(1)" class="px-2 py-1">
            <IconBlogDoubleArrowLeft class="fill-white !w-3.5 !h-3.5 cursor-pointer" />
        </NuxtLink>
        <NuxtLink v-if="currentPage > 1" :to="pageLink(currentPage - 1)" class="px-2 py-1 rounded">
            <IconBlogArrowLeft class="fill-white !w-3.5 !h-3.5 cursor-pointer" />
        </NuxtLink>
        <span v-if="showEllipsisBefore">…</span>
        <span v-for="page in pagesInRange" :key="page" class="px-2 py-1 rounded cursor-pointer">
            <NuxtLink :to="pageLink(page)" :class="{
                'bg-azure w-7 h-7 flex justify-center items-center text-white rounded-full transition-all':
                    currentPage === page,
                'hover:opacity-50': currentPage !== page,
            }">
                {{ page }}
            </NuxtLink>
        </span>
        <span v-if="showEllipsis">…</span>
        <NuxtLink v-if="currentPage < totalPages" :to="pageLink(currentPage + 1)" class="px-2 py-1 rounded">
            <IconBlogArrowRight class="fill-white !w-3.5 !h-3.5 cursor-pointer" />
        </NuxtLink>
        <NuxtLink v-if="currentPage < totalPages" :to="pageLink(totalPages)" class="px-2 py-1">
            <IconBlogDoubleArrowRight class="fill-white !w-3.5 !h-3.5 cursor-pointer" />
        </NuxtLink>
    </div>
</template>

<script setup>
const route = useRoute();
const props = defineProps({
    totalItems: {
        type: Number,
        required: true,
    },
    itemsPerPage: {
        type: Number,
        required: true,
    },
    currentPage: {
        type: Number,
        required: true,
    },
});

const totalPages = computed(() => {
    return Math.ceil(props.totalItems / props.itemsPerPage);
});

const pageLink = (page) => {
    const basePath = route.path.split('?')[0];
    if (page === 1) {
        const q = { ... route.query };
        delete q.page;
        return {
          path: basePath,
          query: q
        };
    } else {
        return {
            path: basePath,
            query: { ...route.query, page },
        };
    }
};
const pagesInRange = computed(() => {
    const totalVisiblePages = 5;
    let start = props.currentPage - Math.floor(totalVisiblePages / 2);
    let end = props.currentPage + Math.floor(totalVisiblePages / 2);

    if (start < 1) {
        start = 1;
        end = Math.min(totalPages.value, totalVisiblePages);
    }

    if (end > totalPages.value) {
        end = totalPages.value;
        start = Math.max(1, totalPages.value - totalVisiblePages + 1);
    }

    const range = [];
    for (let i = start; i <= end; i++) {
        range.push(i);
    }

    return range;
});
const showEllipsis = computed(() => {
    return pagesInRange.value[pagesInRange.value.length - 1] < totalPages.value;
});
const showEllipsisBefore = computed(() => {
    return pagesInRange.value[0] > 1;
});
</script>
